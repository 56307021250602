import request from '@/utils/request'

// 获取案例列表
export const getCaseList = (params) => {
    return request.get('admin/caseList', { params })
}

// 添加和修改
export const updateCase = (params) => {
    return request.post('admin/caseDetail', params)
}

// 获取详情
export const getCaseInfo = (params) => {
    return request.get('admin/caseDetail', { params })
}

// 案例删除
export const delCase = (params) => {
    return request.get('admin/caseDel', { params })
}
