<!-- 历史案例 -->
<template>
  <Container>
    <template #header>
      <el-button-group>
        <el-button type="primary" @click="onAdd">新增</el-button>
      </el-button-group>
    </template>
    <Table :head-config="listConfig.headConfig" :load-data="listConfig.dataList">
      <template v-slot:masterImg="scope">
        <el-image style="width: 100px; height: 100px" :src="scope.data.row.master_img" />
      </template>
      <template v-slot:handle="scope">
        <el-button type="primary" size="mini" @click="edit(scope.data)">编辑</el-button>
        <el-button type="danger" size="mini" @click="del(scope.data)">删除</el-button>
      </template>
    </Table>

    <CaseView ref="caseView" :form-data.sync="form" @submit="submit" />
  </Container>
</template>

<script>
import CaseView from './components/CaseView'
import { getCaseList, updateCase, getCaseInfo, delCase } from '@/api/case'
export default {
    components: { CaseView },
    data() {
        return {
            listConfig: {
                headConfig: [ // 表头数据
                    { label: '案例标题', field: 'title' },
                    { label: '图片', columnType: 'slot', slotName: 'masterImg' },
                    { label: '描述', field: 'introduce' },
                    { label: '时间', field: 'time' },
                    { label: '操作', columnType: 'slot', slotName: 'handle', width: 180 }
                ],
                dataList: [], // table数据
                pageData: { total: 0, pageIndex: 1, pageSize: 10 } // 分页数据
            },
            form: {
                title: '',
                master_img: '',
                introduce: ''
            }
        }
    },
    computed: { },
    watch: {},
    created() {
        this.getList()
    },
    mounted() { },
    methods: {
        getList() {
            getCaseList().then(res => {
                if (res.code === 0) {
                    this.listConfig.dataList = res.list
                }
            })
        },
        onAdd() {
            this.$refs.caseView.openDialog({ sign: 'add', title: '新增', switch: true })
        },
        edit(e) {
            getCaseInfo({ id: e.row.id }).then(res => {
                if (res.code === 0) {
                    this.form = res.list
                    console.log(this.form)
                    this.$refs.caseView.openDialog({ sign: 'edit', title: '编辑', switch: true })
                }
            })
        },
        submit(val) {
            updateCase(val.formData).then(res => {
                if (res.code === 0) {
                    this.$refs.caseView.resetFields()
                    this.$refs.caseView.dialog.switch = false
                    this.getList()
                }
            })
        },
        del(v) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delCase({ id: v.row.id }).then(res => {
                    this.getList()
                })
            })
        }
    }
}
</script>

